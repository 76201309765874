import { BrowserRouter, Route, Routes } from "react-router-dom";
import Welcome from "./pages/Welcome";
import DCLeak from './pages/DCLeak';
import AdminUpload from './components/AdminUpload';
import Downloads from './components/Downloads';
import { getDownloadURL, ref } from '@firebase/storage';
import { storage } from './firebase-config';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FTPInstructions from './components/FTPInstructions';

export default function Router() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Welcome />} />
                {/* <Route path="/dcleak" element={<DCLeak />} /> */}
                <Route path="/portal" element={<AdminUpload />} />
                <Route path="/downloads" element={<Downloads />} />
                <Route path="/dl/*" element={<FileDownload />} />
                <Route path="/ftp-setup" element={<FTPInstructions />} />
            </Routes>
        </BrowserRouter>
    )
}

function FileDownload() {
  const navigate = useNavigate();
  const location = window.location.pathname;
  const filePath = location.replace('/dl/', '');

  useEffect(() => {
    const getFile = async () => {
      try {
        console.log('Attempting to download file:', filePath);
        const fileRef = ref(storage, `dl/${filePath}`);
        const url = await getDownloadURL(fileRef);
        
        // Get the actual filename from the path
        const actualFileName = filePath.split('/').pop();
        
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = actualFileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.error('Download error:', error, 'for path:', filePath);
        navigate('/downloads');
      }
    };

    getFile();
  }, [filePath, navigate]);

  return <div className="text-xl">Starting download...</div>;
}