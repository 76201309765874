import { useState, useEffect } from 'react';
import { storage } from '../firebase-config';
import { ref, listAll, getDownloadURL } from '@firebase/storage';
import { useNavigate, Link } from 'react-router-dom';
import { logEvent } from '../utils/analytics';

export default function Downloads() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    loadFiles();
  }, []);

  const loadFiles = async () => {
    try {
      setLoading(true);
      const listRef = ref(storage, 'dl');
      console.log('Checking directory: dl/');
      
      // Recursively list all files including those in subdirectories
      const listAllFiles = async (reference) => {
        const result = await listAll(reference);
        
        const files = await Promise.all([
          // Get files in current directory
          ...result.items.map(async (item) => {
            try {
              const url = await getDownloadURL(item);
              return {
                name: item.name,
                url: url,
                path: item.fullPath,
                // Extract relative path from full path (remove 'dl/')
                relativePath: item.fullPath.replace('dl/', '')
              };
            } catch (error) {
              console.error(`Error processing file ${item.name}:`, error);
              return null;
            }
          }),
          // Recursively get files from subdirectories
          ...result.prefixes.map(prefix => listAllFiles(prefix))
        ]);
        
        // Flatten the array and remove nulls
        return files.flat().filter(file => file !== null);
      };
      
      const allFiles = await listAllFiles(listRef);
      console.log('Valid files found:', allFiles.length);
      setFiles(allFiles);
    } catch (error) {
      console.error('Error loading files:', error);
      setError('Failed to load files. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (file) => {
    try {
      // Step 1: Get fresh download URL
      const fileRef = ref(storage, file.path);
      const downloadURL = await getDownloadURL(fileRef);
      
      // Step 2: Fetch the file
      const response = await fetch(downloadURL);
      if (!response.ok) throw new Error('Download failed');
      
      // Step 3: Get the blob
      const blob = await response.blob();
      
      // Step 4: Create download link
      const blobUrl = window.URL.createObjectURL(blob);
      const downloadElement = document.createElement('a');
      downloadElement.style.display = 'none';
      downloadElement.href = blobUrl;
      downloadElement.download = file.name;
      
      // Step 5: Trigger download
      document.body.appendChild(downloadElement);
      downloadElement.click();
      
      // Step 6: Cleanup
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(blobUrl);
      
      // Step 7: Track download
      logEvent('download', { filename: file.name });
      
    } catch (error) {
      console.error('Download failed:', error);
      setError('Download failed. Please try again.');
    }
  };

  const handleCopyLink = async (file) => {
    try {
      // Make sure we're using the full path from Firebase
      const linkText = `${window.location.origin}/dl/${file.relativePath}`;
      console.log('Copying link:', linkText);
      await navigator.clipboard.writeText(linkText);
      logEvent('copy_link', { filename: file.relativePath });
    } catch (error) {
      console.error('Copy error:', error);
      setError('Failed to copy link');
    }
  };

  const filteredFiles = searchQuery
    ? files.filter(file => 
        file.name.toLowerCase().includes(searchQuery.toLowerCase().trim())
      )
    : files;

  return (
    <div className="w-full flex flex-row justify-center" style={{ backgroundColor: '#f6f6f6', minHeight: '100vh' }}>
      <div className="flex flex-col gap-y-[20px] relative font-normal jost w-full max-w-[1400px] px-4 sm:px-6 md:px-[5vw] pb-[0px]">
        <div className="h-[60px] sm:h-[80px] md:h-[100px]"></div>
        
        <div className="flex justify-between items-center mb-12 sm:mb-16 md:mb-24">
          <div className="text-lg sm:text-xl">DOWNLOADS</div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="SEARCH"
            className="text-lg sm:text-xl bg-transparent focus:outline-none text-right w-[200px]"
          />
        </div>

        {error && (
          <div className="text-red-500 text-lg mb-4">{error}</div>
        )}

        {loading ? (
          <div className="text-lg sm:text-xl opacity-50">
            Loading<span className="searching-dots"></span>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {filteredFiles.map((file, index) => (
              <div key={index} className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 sm:gap-0">
                <span className="text-lg sm:text-xl break-all sm:break-normal sm:max-w-[40%]">{file.name}</span>
                <div className="flex flex-wrap gap-4 sm:gap-8">
                  <button
                    onClick={() => handleDownload(file)}
                    className="text-lg sm:text-xl hover:opacity-60 transition-opacity"
                  >
                    DOWNLOAD
                  </button>
                  <button
                    onClick={() => handleCopyLink(file)}
                    className="text-lg sm:text-xl hover:opacity-60 transition-opacity"
                  >
                    COPY LINK
                  </button>
                </div>
              </div>
            ))}

            {!loading && filteredFiles.length === 0 && (
              <div className="text-lg sm:text-xl opacity-50">
                {searchQuery ? 'No matching files found' : 'No files available'}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}