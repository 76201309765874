import React, { useEffect } from "react";
import arrow from "../assets/images/curved-arrow.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Marquee from "react-fast-marquee";
import img1 from "../assets/images/up/1.png";
import img2 from "../assets/images/up/2.png";
import img3 from "../assets/images/up/3.png";
import img4 from "../assets/images/up/4.png";
import img5 from "../assets/images/up/5.png";
import img6 from "../assets/images/up/6.png";
import img7 from "../assets/images/up/7.png";
import img8 from "../assets/images/up/8.png";
import img9 from "../assets/images/up/9.png";
import img10 from "../assets/images/up/10.png";


import _img1 from "../assets/images/down/1.png";
import _img2 from "../assets/images/down/2.png";
import _img3 from "../assets/images/down/3.png";
import _img4 from "../assets/images/down/4.png";
import _img5 from "../assets/images/down/5.png";
import _img6 from "../assets/images/down/6.png";
import _img7 from "../assets/images/down/7.png";
import _img8 from "../assets/images/down/8.png";
import _img9 from "../assets/images/down/9.png";

import tarantulaSvg from "../assets/TNEWLOGO7777.svg";



export default function Welcome() {
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];
  const images1 = [
    _img1,
    _img2,
    _img3,
    _img4,
    _img5,
    _img6,
    _img7,
    _img8,
    _img9,
  ];

  const [logoWidth, setLogoWidth] = useState();
  const [deltaWidth, setDeltaWidth] = useState();
  const [isMounted, setIsMounted] = useState(false);



  useEffect(() => {
    const handleResize = () => {
      const paragraph = document.querySelector(".mb-4");
      const text = "TALENT MANAGEMENT AND STRATEGIC CONSULTING";

      // Create a temporary span to measure the width
      const tempSpan = document.createElement("span");
      tempSpan.style.visibility = "hidden"; // Keep it invisible
      tempSpan.style.whiteSpace = "nowrap"; // Prevent wrapping
      tempSpan.style.fontSize = window.getComputedStyle(paragraph).fontSize;
      tempSpan.style.fontFamily = window.getComputedStyle(paragraph).fontFamily;
      tempSpan.style.fontWeight = window.getComputedStyle(paragraph).fontWeight;

      tempSpan.innerText = text;
      document.body.appendChild(tempSpan);

      const textWidth = tempSpan.offsetWidth;
      document.body.removeChild(tempSpan);

      setLogoWidth(textWidth);
      let _delta = 30 + Math.floor((window.innerWidth - 500) / 50);
      setDeltaWidth(_delta);
    };

    // Wait for fonts to load before calculating
    document.fonts.ready.then(() => {
      handleResize();
      setIsMounted(true);
    });

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="w-full min-h-screen flex flex-row justify-center"
      style={{ backgroundColor: "#f6f6f6" }}
    >
      <div className={`welcome-container flex flex-col relative font-normal jost w-full max-w-[1400px] px-[4vw] ${isMounted ? 'mounted' : ''}`}>
        {/* Adjusted top spacing */}
        <div className="h-[2vh] md:h-[10vh]"></div>

        {/* Adjusted logo container for exact alignment and size */}
        <div
          className="
          tarantula
          max-w-[2400px] 
          -ml-[1vw]         // Slightly increased mobile margin
          sm:-ml-[1vw]   // Smoother transition
          md:-ml-[1vw]   // Further adjustment
          lg:-ml-[1vw]   // Increased desktop margin to fix T alignment
          mb-8 
          md:mb-16
          relative
        "
          style={{
            width: `${logoWidth + deltaWidth}px`,
          }}
        >
          <img
            src={tarantulaSvg}
            alt="TARANTULA"
            className="w-full tarantula-logo"
            style={{
              objectFit: "contain",
              maxWidth: "100%",
              transform: "translateX(-1%)", // Slight adjustment to fix T alignment
            }}
          />
        </div>

        {/* Main Text - reduced spacing on mobile */}
        <div className="mb-8 md:mb-16">
          <p className="mb-4 md:mb-8">
            TALENT MANAGEMENT AND STRATEGIC CONSULTING
            <br />
            FOR ICONS, ARTISTS, GENIUSES AND HEADS OF STATE
          </p>

          <p className="mb-8 md:mb-16">
            MILO YIANNOPOULOS AND TWO SILENT PARTNERS OFFER
            <br />
            UNRIVALLED CONNECTIONS, CAPITAL AND EXPERTISE
          </p>

          <p>PAST AND PRESENT CLIENTS</p>
        </div>

        {/* Marquee Section - adjusted spacing */}
        <div className="w-full flex flex-col gap-y-[15px] md:gap-y-[30px] mb-8 md:mb-16">
          <div className="marquee-wrapper">
            <Marquee pauseOnHover={true} speed={50}>
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Slide ${index}`}
                  className="marquee-image"
                />
              ))}
            </Marquee>
          </div>

          <div className="marquee-wrapper">
            <Marquee pauseOnHover={true} speed={50} direction="right">
              {images1.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Slide ${index}`}
                  className="marquee-image"
                />
              ))}
            </Marquee>
          </div>
        </div>

        {/* Footer Text */}
        <div className="mb-[2vh] md:mb-[5vh]">
          <p className="mb-4">
            © TARANTULA MANAGEMENT, LLC
            <br />
            WRITE TO MY@TRNT.LA OR CALL 310 600 1026
          </p>
          <p>WE ARE NOT ACCEPTING NEW CLIENTS AT THIS TIME</p>
        </div>
      </div>
    </div>
  );
}
